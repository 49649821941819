import './utils/vh.utils.js';
import './utils/safari.utils.js';

import './utils/animate-on-view.utils.js';
import './utils/parallax-on-view.utills.js';

// import './utils/accordion.utills.js';

import './components/header.component.js';
// import './components/buttons.component.js';
import './components/animations.component.js';

/*import './components/uploadFile.component.js';*/
import './components/inputs.component.js';

/*Sliders*/
import './components/lodges.component.js';
import './components/testimonials.component.js';
import './components/unwind.component.js';
import './components/our-lodges.component.js';
import './components/attractions.component.js';
import './components/detail.component.js';
import './components/gallery.component.js';

/*End sliders*/

import './components/other.component.js';

import './libs/countUp.lib.js';
/*import Scrollbar from 'smooth-scrollbar';*/

/*if (document.querySelector('[data-scrollbar-init]')) {
  const scrollbarArr = document.querySelectorAll('[data-scrollbar-init]');
  scrollbarArr.forEach((scrollbar) => {
    Scrollbar.init(scrollbar, {});
  });
}*/

function accordionFunc(elem) {
  const itemsArr = elem.querySelectorAll('[data-accordion-item]');
  itemsArr.forEach((item, i) => {
    item.addEventListener('click', (e) => {
      /*const isClickable = (element) => {
        if (element === item) {
          return true;
        }
        if (element.hasAttribute('data-accordion-not-toggle')) {
          return false;
        }
        if (element.parentElement) {
          return isClickable(element.parentElement);
        }
        return true;
      };

      if (isClickable(e.target)) {
        item.classList.toggle('active');
      }*/

      if (item.classList.contains('active')) {
        item.classList.remove('active');
      } else {
        for (let j = 0; j < itemsArr.length; j++) {
          if (itemsArr[j].classList.contains('active')) {
            itemsArr[j].classList.remove('active');
          }
        }

        item.classList.add('active');
      }
    });
  });
}

window.accordionInit = function accordionInit() {
  if (document.querySelector('[data-accordion]')) {
    const accordionsArr = document.querySelectorAll('[data-accordion]');
    accordionsArr.forEach((accordion) => {
      accordionFunc(accordion);
    });
  }
};

window.calendarOpenPopup = function calendarOpenPopup() {
  if (document.getElementById('calendar-popup')) {
    const calendarPopup = document.querySelector('#calendar-popup');
    const calendarPopupBlock = calendarPopup.querySelector(
      '.calendar-popup__block'
    );

    calendarPopup.classList.add('active');

    scrollLock.addScrollableSelector('.simplebar-content-wrapper');
  }
};

window.calendarClosePopup = function calendarClosePopup() {
  document.querySelector('#calendar-popup').classList.remove('active');
  scrollLock.clearQueueScrollLocks();
  scrollLock.enablePageScroll();
};

/*var popupFD = document.querySelector('#popup');
if (popupFD) {
  /!*var popupTitle = popupFD.querySelector(".popup__title");*!/
  var popupBlock = popupFD.querySelector('#popup-block');

  window.pushPopup = function pushPopup(parentBlock, classHelper = '') {
    console.log(parentBlock);
    if (classHelper) {
      const cls = classHelper.split(' ');
      popupBlock.classList.add(...cls);
    }

    popupBlock.innerHTML = '';
    popupBlock.innerHTML = document.querySelector(parentBlock).innerHTML;

    popupFD.classList.add('active');
    scrollLock.disablePageScroll(document.querySelector('.navbar-nav .menu'));
    scrollLock.addScrollableSelector('.simplebar-content-wrapper');

    const scrollContentElement = document.querySelector('.popup__content');
    console.log(scrollContentElement);

    if (scrollContentElement) {
      const simpleBarInstance = new SimpleBar(scrollContentElement);
      simpleBarInstance.recalculate();
    }

    scrollLock.addScrollableSelector('.simplebar-content-wrapper');

    setTimeout(accordionInit, 300);
    console.log(accordionInit);

    if (popupFD.querySelector('.faq-accordion__item-text')) {
      const faqTextArr = popupFD.querySelectorAll('.faq-accordion__item-text');
      for (let i = 0; i < faqTextArr.length; i++) {
        new SimpleBar(faqTextArr[i]);
        setTimeout(function () {
          const scrollBlock = faqTextArr[i];
          const scrollsHorizontalArr = scrollBlock.querySelectorAll(
            '.simplebar-horizontal'
          );
          const scrollsVerticalArr = scrollBlock.querySelectorAll(
            '.simplebar-vertical'
          );
          if (scrollsHorizontalArr.length >= 1) {
            for (let i = 0; i < scrollsHorizontalArr.length; i++) {
              if (i !== 0) {
                scrollsHorizontalArr[i].remove();
                scrollsVerticalArr[i].remove();
              }
            }
          }
        }, 500);
      }
    }
  };
}*/

window.faqPopupLogic = function faqPopupLogic() {
  const faqTextArr = document.querySelectorAll('.faq-accordion__item-text');
  for (let i = 0; i < faqTextArr.length; i++) {
    new SimpleBar(faqTextArr[i]);
    setTimeout(function () {
      const scrollBlock = faqTextArr[i];
      const scrollsHorizontalArr = scrollBlock.querySelectorAll(
        '.simplebar-horizontal'
      );
      const scrollsVerticalArr = scrollBlock.querySelectorAll(
        '.simplebar-vertical'
      );
      if (scrollsHorizontalArr.length >= 1) {
        for (let i = 0; i < scrollsHorizontalArr.length; i++) {
          if (i !== 0) {
            scrollsHorizontalArr[i].remove();
            scrollsVerticalArr[i].remove();
          }
        }
      }
    }, 500);
  }
};

/*window.closePopup = function closePopup() {
  document.querySelector('#popup').classList.remove('active');
  scrollLock.clearQueueScrollLocks();
  scrollLock.enablePageScroll();
};*/

window.addDisableStatus = function addDisableStatus(id) {
  const btn = document.getElementById(id);
  console.log(btn);
  if (btn) {
    btn.classList.add('disable-status');
  }
};

window.removeDisableStatus = function removeDisableStatus(id) {
  const btn = document.getElementById(id);
  console.log(btn);
  if (btn) {
    btn.classList.remove('disable-status');
  }
};

/*document.querySelectorAll('video[autoplay]').forEach(function (video) {
  video.play();
});*/
