import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

if (document.getElementsByClassName('testimonials-section__slider')[0]) {
  const testimonialsSlidersArr = document.querySelectorAll(
    '.testimonials-section__slider'
  );
  testimonialsSlidersArr.forEach((sliderEl, sliderIdx) => {
    const slides = sliderEl.querySelectorAll('.swiper-slide');
    let loop = false;
    let loopedSlides = false;

    if (slides.length >= 4) {
      loop = true;
      loopedSlides = slides.length * 4;
    }

    const testimonialsSwiper = new Swiper(sliderEl, {
      modules: [Autoplay, Navigation],
      slidesPerView: 1,
      spaceBetween: 16,
      threshold: 10,
      loop: loop,
      loopedSlides: loopedSlides,
      autoplay: {
        delay: 3200,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        prevEl: sliderEl.querySelector('.slider-buttons .slider-btn.prev'),
        nextEl: sliderEl.querySelector('.slider-buttons .slider-btn.next'),
      },
      breakpoints: {
        551: {
          spaceBetween: 26,
          slidesPerView: 1,
        },
      },
    });

    swiperObserver(testimonialsSwiper);

    /*setTimeout(function () {
      const textItemsArr = document.querySelectorAll(
        '.testimonials-section__item-text'
      );
      textItemsArr.forEach((text) => {
        new SimpleBar(text);
      });
    }, 50);*/
  });
}
