import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

if (document.getElementsByClassName('lodges-section__slider')[0]) {
  const lodgesSlidersArr = document.querySelectorAll('.lodges-section__slider');
  lodgesSlidersArr.forEach((sliderEl, sliderIdx) => {
    /*const slides = sliderEl.querySelectorAll('.swiper-slide');
    let loop = false;
    let loopedSlides = false;

    if (slides.length >= 4) {
      loop = true;
      loopedSlides = slides.length * 4;
    }*/

    const lodgesSwiper = new Swiper(sliderEl, {
      modules: [Autoplay, Navigation],
      slidesPerView: 1,
      spaceBetween: 30,
      threshold: 10,
      /*loop: loop,
      loopedSlides: loopedSlides,*/
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        prevEl: sliderEl.querySelector('.slider-buttons .slider-btn.prev'),
        nextEl: sliderEl.querySelector('.slider-buttons .slider-btn.next'),
      },
      breakpoints: {
        551: {
          spaceBetween: 16,
          slidesPerView: 2,
        },
        651: {
          spaceBetween: 16,
          slidesPerView: 2.4,
        },
        901: {
          spaceBetween: 18,
          slidesPerView: 3,
        },
        1025: {
          spaceBetween: 26,
          slidesPerView: 3,
        },
      },
    });

    swiperObserver(lodgesSwiper);
  });
}
