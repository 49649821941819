/*SD => php*/
const benefitsList = document.querySelectorAll('.benefits-section__list');

if (benefitsList.length > 0) {
  benefitsList.forEach((list) => {
    const itemsArr = list.querySelectorAll('.benefits-section__item');
    if (itemsArr.length >= 6) {
      list.classList.add('with-6-items');
    }
  });
}
