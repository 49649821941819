import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

if (document.getElementsByClassName('unwind-section__slider')[0]) {
  const unwindSlidersArr = document.querySelectorAll('.unwind-section__slider');
  unwindSlidersArr.forEach((sliderEl, sliderIdx) => {
    const slides = sliderEl.querySelectorAll('.swiper-slide');
    /*let loop = false;
    let loopedSlides = false;

    if (slides.length >= 4) {
      loop = true;
      loopedSlides = slides.length * 4;
    }*/

    if (slides.length >= 3) {
      const unwindSwiper = new Swiper(sliderEl, {
        modules: [Autoplay, Navigation],
        slidesPerView: 1,
        spaceBetween: 16,
        threshold: 10,
        // loop: loop,
        // loopedSlides: loopedSlides,
        autoplay: {
          delay: 3500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },
        navigation: {
          prevEl: sliderEl.querySelector('.slider-buttons .slider-btn.prev'),
          nextEl: sliderEl.querySelector('.slider-buttons .slider-btn.next'),
        },
        breakpoints: {
          640: {
            spaceBetween: 26,
            slidesPerView: 2,
          },
        },
      });

      swiperObserver(unwindSwiper);
    }
  });
}
