import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

if (document.getElementsByClassName('our-lodges-slider')[0]) {
  const ourLodgesSlidersArr = document.querySelectorAll('.our-lodges-slider');
  ourLodgesSlidersArr.forEach((sliderEl, sliderIdx) => {
    /*const slides = sliderEl.querySelectorAll('.swiper-slide');
    let loop = false;
    let loopedSlides = false;

    if (slides.length >= 4) {
      loop = true;
      loopedSlides = slides.length * 4;
    }*/

    const ourLodgesSwiper = new Swiper(sliderEl, {
      modules: [Autoplay, Navigation, Pagination],
      slidesPerView: 1,
      spaceBetween: 30,
      threshold: 10,
      /*loop: loop,
      loopedSlides: loopedSlides,*/
      autoplay: {
        delay: 3200,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        prevEl: sliderEl.querySelector('.slider-buttons .slider-btn.prev'),
        nextEl: sliderEl.querySelector('.slider-buttons .slider-btn.next'),
      },
      breakpoints: {
        551: {
          spaceBetween: 16,
          slidesPerView: 2,
        },
        651: {
          spaceBetween: 16,
          slidesPerView: 2.4,
        },
        901: {
          spaceBetween: 18,
          slidesPerView: 3,
        },
        1025: {
          spaceBetween: 26,
          slidesPerView: 3,
        },
      },
    });

    /*swiperObserver(ourLodgesSwiper);*/
  });
}

if (document.getElementsByClassName('images-lodge-slider')[0]) {
  const imagesLodgeSlidersArr = document.querySelectorAll(
    '.images-lodge-slider'
  );
  const imagesLodgeNavigationArr = document.querySelectorAll(
    '.images-lodge-slider-buttons'
  );
  const imagesLodgePaginationArr = document.querySelectorAll(
    '.images-lodge-slider-pagination'
  );
  imagesLodgeSlidersArr.forEach((sliderEl, sliderIdx) => {
    /*const slides = sliderEl.querySelectorAll('.swiper-slide');
    let loop = false;
    let loopedSlides = false;

    if (slides.length >= 4) {
      loop = true;
      loopedSlides = slides.length * 4;
    }*/
    const delay = `5${sliderIdx}00`;

    const imagesLodgeSwiper = new Swiper(sliderEl, {
      modules: [Autoplay, Navigation, Pagination],
      slidesPerView: 1,
      spaceBetween: 0,
      threshold: 10,
      /*loop: loop,
      loopedSlides: loopedSlides,*/
      allowTouchMove: false,
      autoplay: {
        delay: Number(delay),
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        prevEl: imagesLodgeNavigationArr[sliderIdx].querySelector(
          '.second-slider-btn.prev'
        ),
        nextEl: imagesLodgeNavigationArr[sliderIdx].querySelector(
          '.second-slider-btn.next'
        ),
      },
      pagination: {
        el: imagesLodgePaginationArr[sliderIdx],
        clickable: true,
      },
    });

    swiperObserver(imagesLodgeSwiper);
  });
}

const firstSectionLodgesType = document.querySelector(
  '.first-section.lodges-type'
);

if (firstSectionLodgesType) {
  const firstBg = firstSectionLodgesType.querySelector('.first-section__bg');

  if (
    firstSectionLodgesType.nextElementSibling &&
    firstSectionLodgesType.nextElementSibling.classList.contains('map-section')
  ) {
    const mapSection = firstSectionLodgesType.nextElementSibling;

    let margin = checkMargin();
    mapSection.style.marginTop = `-${margin}px`;

    setTimeout(function () {
      let margin = checkMargin();
      mapSection.style.marginTop = `-${margin}px`;
    }, 1000);

    function checkMargin() {
      return firstSectionLodgesType.clientHeight - firstBg.clientHeight;
    }

    function handleResize() {
      margin = checkMargin();
      mapSection.style.marginTop = `-${margin}px`;
    }

    window.addEventListener('resize', handleResize);
  }
}
