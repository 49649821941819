import Swiper, { Autoplay, Navigation } from 'swiper';

if (document.getElementsByClassName('attractions-section__slider')[0]) {
  const attractionsSlidersArr = document.querySelectorAll(
    '.attractions-section__slider'
  );

  attractionsSlidersArr.forEach((item, i) => {
    const btn = item.querySelector('.attractions-section__expand.for-desk');
    const attractionsArr = item.querySelectorAll('.attractions-section__item');

    if (btn) {
      const btnText = btn.querySelector('.btn-link__text');
      attractionsArr.forEach((attraction, i) => {
        if (i >= 3) {
          attraction.style.display = 'none';
          attraction.style.opacity = '0';
        }
      });

      btn.addEventListener('click', () => {
        if (btn.classList.contains('is-open')) {
          btn.classList.remove('is-open');
          btnText.textContent = 'expand the list';

          attractionsArr.forEach((attraction, i) => {
            if (i >= 3) {
              attraction.style.opacity = '0';
              setTimeout(function () {
                attraction.style.display = 'none';
              }, 50);
            }
          });
        } else {
          btn.classList.add('is-open');
          btnText.textContent = 'collapse the list';

          attractionsArr.forEach((attraction, i) => {
            if (i >= 3) {
              attraction.style.display = 'flex';
              setTimeout(function () {
                attraction.style.opacity = '1';
              }, 50);
            }
          });
        }
      });
    }
  });

  let initSwiper = false;
  let attractionsSwipers = [];

  function initializeSliders() {
    attractionsSwipers.forEach((swiper) => {
      swiper.destroy();
    });

    attractionsSwipers = [];

    attractionsSlidersArr.forEach((sliderEl, sliderIdx) => {
      if (window.innerWidth <= 640) {
        const attractionsSwiper = new Swiper(sliderEl, {
          modules: [Autoplay, Navigation],
          slidesPerView: 1,
          spaceBetween: 20,
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          },
          navigation: {
            prevEl: sliderEl.querySelector('.slider-buttons .slider-btn.prev'),
            nextEl: sliderEl.querySelector('.slider-buttons .slider-btn.next'),
          },
          breakpoints: {
            451: {
              spaceBetween: 16,
              slidesPerView: 1.2,
            },
            571: {
              spaceBetween: 16,
              slidesPerView: 1.5,
            },
          },
        });

        attractionsSwipers.push(attractionsSwiper);
      }
    });
  }

  initializeSliders();

  window.addEventListener('resize', () => {
    if (window.innerWidth <= 640) {
      if (initSwiper !== true) {
        initializeSliders();
        randomRotationFunc();
        console.log('resize');
        initSwiper = true;
      }
    } else if (window.innerWidth > 640) {
      attractionsSwipers.forEach((swiper) => {
        swiper.destroy();
        randomRotationFunc();
      });
      initSwiper = false;
    }
  });

  function randomRotationFunc() {
    attractionsSlidersArr.forEach((parent) => {
      const itemsArr = parent.querySelectorAll('.attractions-section__item');
      itemsArr.forEach((item, i) => {
        const randomRotation = Math.random() * 4 - 2 + 'deg';
        item.style.transform = 'rotate(' + randomRotation + ')';

        if (i === 0) {
          item.style.transform = 'rotate(-1deg)';
        } else if (i === 1 && itemsArr.length < 4) {
          item.style.transform = 'rotate(2deg)';
        } else if (i === 2 && itemsArr.length < 4) {
          item.style.transform = 'rotate(2deg)';
        } else if (i === 1 && itemsArr.length >= 4) {
          item.style.transform = 'rotate(2deg)';
        } else if (i === 2 && itemsArr.length >= 4) {
          item.style.transform = 'rotate(1deg)';
        } else if (i === 3 && itemsArr.length >= 4) {
          item.style.transform = 'rotate(3deg)';
        } else if (i === 4 && itemsArr.length >= 4) {
          item.style.transform = 'rotate(-1deg)';
        } else if (i === 5 && itemsArr.length >= 4) {
          item.style.transform = 'rotate(-2deg)';
        }
      });
    });
  }
  randomRotationFunc();
}
