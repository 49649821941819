import Swiper, { Autoplay, Navigation, Thumbs } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

if (document.getElementsByClassName('detail-section__sliders')[0]) {
  const detailSlidersArr = document.querySelectorAll(
    '.detail-section__sliders'
  );
  detailSlidersArr.forEach((slidersEl, sliderIdx) => {
    const mainSliderEl = slidersEl.querySelector(
      '.detail-section__main-slider'
    );
    const navSliderEl = slidersEl.querySelector('.detail-section__nav-slider');

    /*const slides = sliderEl.querySelectorAll('.swiper-slide');
    let loop = false;
    let loopedSlides = false;

    if (slides.length >= 4) {
      loop = true;
      loopedSlides = slides.length * 4;
    }*/

    const navSwiper = new Swiper(navSliderEl, {
      modules: [Autoplay, Navigation, Thumbs],
      slidesPerView: 5,
      spaceBetween: 6,
      threshold: 10,
      /*loop: loop,
      loopedSlides: loopedSlides,*/
      /*autoplay: {
        delay: 3500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },*/
      /*slideToClickedSlide: true,*/

      breakpoints: {
        426: {
          spaceBetween: 8,
          slidesPerView: 5,
        },
        551: {
          spaceBetween: 8,
          slidesPerView: 6,
        },
        641: {
          spaceBetween: 8,
          slidesPerView: 8,
        },
        901: {
          spaceBetween: 8,
          slidesPerView: 6,
        },
        1025: {
          spaceBetween: 8,
          slidesPerView: 7,
        },
        1250: {
          spaceBetween: 9,
          slidesPerView: 8,
        },
      },
    });

    const mainSwiper = new Swiper(mainSliderEl, {
      modules: [Autoplay, Navigation, Thumbs],
      slidesPerView: 1,
      spaceBetween: 0,
      threshold: 10,
      /*loop: loop,
      loopedSlides: loopedSlides,*/
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        prevEl: slidersEl.querySelector('.second-slider-btn.prev'),
        nextEl: slidersEl.querySelector('.second-slider-btn.next'),
      },
      thumbs: {
        swiper: navSwiper,
      },
    });

    swiperObserver(mainSwiper);
  });
}
